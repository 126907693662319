'use client'
import {createContext, useContext, useState, ReactNode, useEffect} from 'react'
import carsApiRequest from "@/api-data/cars";
import {useParams} from 'next/navigation';
import {useMainFilterContext} from "@/components/pages/filter/context/MainFilterContext";
import pricingApiRequest from "@/api-data/pricing";
import {TERM_TYPE} from "@/constants";

const DetailCarContext = createContext<any>(null)

export default function DetailCarProvider({
                                            children,
                                          }: {
  children: ReactNode
}) {
  const params = useParams<{ id: string }>();
  const [detailCar, setDetailCar] = useState<any>(null)
  const [pricingUse, setPricingUse] = useState<any>(null)
  const {setFilterValue, filterValue} = useMainFilterContext()
  const [listPricing, setListPricing] = useState<any>([])

  const callApiDetailCar = async (id: string) => {
    const {payload} = await carsApiRequest.detailCar(Number(id));

    if (payload?.code === 200) {
      setDetailCar(payload?.data);
      setFilterValue({
        ...filterValue,
        location_lat: payload?.data?.location_lat,
        location_lng: payload?.data?.location_lng,
        address: payload?.data?.address,
        distance: 10000
      })
    }
  }

  const callListPricing = async () => {
    const {payload} = await pricingApiRequest.getPricing();
    if (payload.code === 200) {
      if (payload?.data?.list && payload?.data?.list.length > 0) {
        const listPricing = payload?.data?.list.filter((pricing: any) => pricing?.term_type === TERM_TYPE.SHORT)

        setListPricing(listPricing)
        const pricingUse = localStorage.getItem("pricing_use")

        if (pricingUse) {
          setFilterValue({
            ...filterValue,
            pricing_id: parseInt(JSON.parse(pricingUse).id)
          });
          setPricingUse(JSON.parse(pricingUse))
        } else {
          const pricingUse = listPricing.find((pricing: any) => pricing?.show_home)
          setFilterValue({
            ...filterValue,
            pricing_id: listPricing.find((pricing: any) => pricing?.show_home)?.id
          });
          localStorage.setItem("pricing_use", JSON.stringify(pricingUse))
          setPricingUse(pricingUse)
        }
      }
    }
  }

  useEffect(() => {
    callListPricing()
  }, []);

  useEffect(() => {
    if (params?.id) {
      callApiDetailCar(params?.id)
    }
  }, [params?.id])

  return (
    <DetailCarContext.Provider value={{detailCar, setDetailCar, pricingUse, setPricingUse, listPricing}}>
      {children}
    </DetailCarContext.Provider>
  )
}

export function useDetailCarContext() {
  return useContext(DetailCarContext)
}
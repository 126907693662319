export function formatPhoneNumber(phone: string): string {
  // Nếu số điện thoại bắt đầu bằng '0', thay '0' bằng '84'
  if (phone.startsWith('0')) {
    return '84' + phone.substring(1)
  }
  // Nếu số điện thoại bắt đầu bằng '84', giữ nguyên
  else if (phone.startsWith('84')) {
    return phone
  }
  // Trường hợp khác, trả về số điện thoại không thay đổi
  else {
    return phone
  }
}

export function buildCarName(car: any): string {
  let carName = ''
  if (car?.brand?.brand_name) {
    carName += car.brand.brand_name
  } else {
    if (car?.brand_name) {
      carName += car.brand_name
    }
  }

  if (car?.model?.model_name) {
    carName += ` ${car.model.model_name}`
  } else {
    if (car?.model_name) {
      carName += ` ${car.model_name}`
    }
  }

  if (car?.year > 0) {
    carName += ` ${car.year}`
  }

  return carName
}

export function buildCarNameByBookingDetail(bookingDetail: any): string {
  let carName = ''
  if (bookingDetail?.brand_name) {
    carName += bookingDetail.brand_name
  } else {
    if (bookingDetail?.brand_name) {
      carName += bookingDetail.brand_name
    }
  }

  if (bookingDetail?.model_name) {
    carName += ` ${bookingDetail?.model_name}`
  } else {
    if (bookingDetail?.model_name) {
      carName += ` ${bookingDetail.model_name}`
    }
  }

  if (bookingDetail?.year > 0) {
    carName += ` ${bookingDetail.year}`
  }

  return carName
}

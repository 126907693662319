'use client'
import {createContext, useContext, useState, ReactNode, useEffect} from 'react'
import {FilterValue} from "@/types/car";
import carsApiRequest from "@/api-data/cars";
import moment from "moment";

const MainFilterContext = createContext<any>(null)

export default function MainFilterProvider({
                                             children,
                                           }: {
  children: ReactNode
}) {
  const startDate = moment().format("YYYY-MM-DD")
  const endDate = moment().add(1, "days").format("YYYY-MM-DD")

  const [totalCars, setTotalCars] = useState<number>(0)
  const [filterValue, setFilterValue] = useState<FilterValue>({
    address: "1 Hùng Vương, Điện Biên, Ba Đình, Hà Nội, Vietnam",
    location_lat: 21.039491771584526,
    location_lng: 105.83462272121153,
    start_date: startDate,
    end_date: endDate,
    start_time: moment().add(1, "hours").format("HH:00"),
    end_time: moment().add(1, "hours").format("HH:00"),
    pricing_id: 0,
    max_price: 0,
    min_price: 0,
    distance: 10000,
    brand_id: "",
    car_type_id: "",
    model_id: "",
    sort_by: "",
    sort_dir: "",
    page: 1,
    limit: 10,
    sort_type: 1,
    hour: 1,
  })
  const [hiddenFilter, setHiddenFilter] = useState<boolean>(false)
  const [listCars, setListCars] = useState<any>([])

  const callApiCars = async (values: FilterValue) => {
    let newListCar = values?.page > 1 ? listCars : []
    let query = {...filterValue, ...values}
    const {payload} = await carsApiRequest.listCars(query);
    if (payload?.data?.list) {
      setListCars([...newListCar, ...payload?.data?.list.map((car: any) => {
        return {
          ...car,
          show_info: false,
        }
      })]);
      setTotalCars(payload?.data?.total)
    }
  }

  const setFilterLatLngValue = (lat: number, lng: number, address: string) => {
    let query = {...filterValue, location_lat: lat, location_lng: lng, address: address, page: 1}
    callApiCars(query)
    setFilterValue(query)
  }

  const setFilterDistance = (distance: number) => {
    let query = {...filterValue, distance: distance, page: 1}
    callApiCars(query)
    setFilterValue(query)
  }

  const setFilterMinMaxPrice = (minPrice: number, maxPrice: number) => {
    let query = {...filterValue, min_price: minPrice, max_price: maxPrice, page: 1}
    callApiCars(query)
    setFilterValue(query)
  }

  const setFilterSort = (sortType: number) => {
    let query = {...filterValue, sort_type: sortType, page: 1}
    callApiCars(query)
    setFilterValue(query)
  }

  const loadingPage = async () => {
    let query = {...filterValue, page: filterValue.page + 1}
    callApiCars(query)
    setFilterValue(query)
  }

  const setPricingId = (pricingId: number) => {
    let query = {...filterValue, pricing_id: pricingId, page: 1}
    callApiCars(query)
    setFilterValue(query)
  }

  const setListBrandId = (brand: string) => {
    let query = {...filterValue, brand_id: brand, page: 1}
    callApiCars(query)
    setFilterValue(query)
  }

  const setCarTypeId = (carType: string) => {
    let query = {...filterValue, car_type_id: carType, page: 1}
    callApiCars(query)
    setFilterValue(query)
  }

  const setModelId = (model: string) => {
    let query = {...filterValue, model_id: model, page: 1}
    callApiCars(query)
    setFilterValue(query)
  }

  // useEffect(() => {
  //   callApiCars(filterValue)
  // }, [filterValue])

  return (
    <MainFilterContext.Provider
      value={{
        hiddenFilter,
        setHiddenFilter,
        filterValue,
        setFilterValue,
        listCars,
        setListCars,
        callApiCars,
        setFilterLatLngValue,
        setFilterDistance,
        setFilterMinMaxPrice,
        setFilterSort,
        totalCars,
        loadingPage,
        setListBrandId,
        setCarTypeId,
        setModelId,
        setPricingId
      }}
    >
      {children}
    </MainFilterContext.Provider>
  )
}

export function useMainFilterContext() {
  return useContext(MainFilterContext)
}
